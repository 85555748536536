@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rainbow-border-multi {
  0%, 100% { border-color: #FF5733; }
  20% { border-color: #33FF57; }
  40% { border-color: #3357FF; }
  60% { border-color: #FF33A1; }
  80% { border-color: #A133FF; }
}

.animate-gradient-multi {
  animation: rainbow-border-multi 3s infinite alternate;
  background-image: linear-gradient(45deg, #ff0066, #ffcc00, #33ccff, #6600ff, #33ff66);
  background-size: 300% 300%;
  animation: animate-bg 2s infinite ease-in-out;
}

@keyframes animate-bg {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
